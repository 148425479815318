'use client';

import React, { Component, ErrorInfo, ReactNode } from 'react';
interface Props {
  children: ReactNode;
}
interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error
    };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    this.setState({
      error,
      errorInfo
    });
  }
  render(): ReactNode {
    if (this.state.hasError) {
      return <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50" data-sentry-component="ErrorBoundary" data-sentry-source-file="ErrorBoundary.tsx">
          <div className="max-w-md text-center">
            <h1 className="text-4xl font-bold text-red-500">Oops!</h1>
            <p className="mt-4 text-lg text-gray-600">
              Algo deu errado. Estamos trabalhando para corrigir isso.
            </p>
            <button className="mt-6 px-6 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none" onClick={() => window.location.reload()}>
              Recarregar a Página
            </button>
          </div>
        </div>;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;