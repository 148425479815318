'use client';

import { NextPageContext } from 'next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
interface ErrorProps {
  statusCode?: number;
}
const ErrorPage = ({
  statusCode
}: ErrorProps) => {
  return <div className="flex min-h-screen flex-col items-center justify-center px-4 py-8" data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx">
      <div className="relative flex max-w-md flex-col items-center rounded-md bg-white p-8 text-center shadow-md transition-all duration-200 hover:shadow-lg">
        <ExclamationCircleOutlined className="mb-4 text-red-500" style={{
        fontSize: '4rem'
      }} data-sentry-element="ExclamationCircleOutlined" data-sentry-source-file="error.tsx" />

        <h1 className="text-3xl font-bold text-gray-800">
          {statusCode ? `Oops... Erro ${statusCode}` : 'Oops... Algo deu errado!'}
        </h1>

        <p className="mt-4 text-gray-600">
          Não se preocupe, estamos trabalhando para resolver isso.
        </p>
      </div>
    </div>;
};
ErrorPage.getInitialProps = ({
  res,
  err
}: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return {
    statusCode
  };
};
export default ErrorPage;