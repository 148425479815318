'use client';

import React from 'react';
export default function Carregando() {
  return <div id="loading-screen" style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#009af8',
    zIndex: 99999,
    position: 'fixed'
  }} data-sentry-component="Carregando" data-sentry-source-file="Carregando.tsx">
      <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
        <p style={{
        fontSize: '1.5rem',
        color: '#fff',
        marginBottom: '20px'
      }}>
          Carregando...
        </p>
        <div style={{
        width: '20px',
        height: '20px',
        border: '4px solid #fff',
        borderTop: '4px solid transparent',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite'
      }}></div>
      </div>

      <img src="/assets/images/solbra-light.svg" alt="Logo" width="150" style={{
      position: 'absolute',
      bottom: '20px'
    }} />

      <style jsx>{`
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>;
}